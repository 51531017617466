export default function Dashboard() {
  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1>Hello, 宝子🤟</h1>
    </div>
  );
}
