export default function Footer() {
  return (
    <footer
      style={{
        width: "100%",
        height: "40px",
        backgroundColor: "#333",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
      }}
    >
      boningggg
    </footer>
  );
}
